import React, { useState, useEffect, useCallback } from "react"
import { Provider } from "@shopify/app-bridge-react"
import { AppProvider, Page, Frame, Loading } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"
import { useAppBridgeHost } from "../../hooks/useAppBridgeHost"

import { Cookies } from "components/Cookies/Cookies"

const config = require("../../../../.runtimeconfig.json").payments
const shopifyConfig = config?.shopify[process.env.NODE_ENV]

const CookiesPage = () => {
  const host = useAppBridgeHost()

  const [shop, setShop] = useState(null)
  const [apiKey, setApiKey] = useState(null)
  const [requestStorage, setStorage] = useState(false)
  const [showPrompt, setPrompt] = useState(false)

  useEffect(() => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    const shop = urlParams.get("shop")
    const apiKey = shopifyConfig?.api_key

    setShop(shop)
    setApiKey(apiKey)
  }, [])

  const handleStorage = useCallback(() => {
    if (requestStorage) document.requestStorageAccess()
    ReactifyApp.Auth.setStorageShopify(apiKey)
  }, [apiKey, requestStorage])

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (ReactifyApp.Auth.hasStorageAccess()) {
        handleStorage()
      } else {
        setPrompt(true)
        setStorage(true)
      }
    }
  }, [shop, apiKey, handleStorage])

  return (
    <AppProvider>
      {shop && apiKey ? (
        <Provider
          config={{
            apiKey: apiKey,
            host,
            shopOrigin: shop,
            forceRedirect: process.env.NODE_ENV !== "development",
          }}
        >
          <Page title={`Enable Cookies`}>{showPrompt && <Cookies onClick={handleStorage} />}</Page>
        </Provider>
      ) : (
        <Frame>
          <Loading />
        </Frame>
      )}
    </AppProvider>
  )
}

export default CookiesPage
