import React from "react"
import { Card, Stack, Layout, Button } from "@shopify/polaris"

export const Cookies = props => {
  const { onClick } = props

  return (
    <Layout>
      <Layout.Section>
        <Stack vertical={true}>
          <Card title="Cookie Error">
            <Card.Section>
              <p>You must manually enable cookies in this browser in order to use this app within Shopify.</p>
            </Card.Section>
            <Card.Section subdued>
              <p>Cookies let the app authenticate you by temporarily storing your preferences and personal information. They expire after 30 days.</p>
            </Card.Section>
          </Card>
          <Button onClick={onClick} primary>
            Enable Cookies
          </Button>
        </Stack>
      </Layout.Section>
    </Layout>
  )
}
